import {compareApi} from "./../compare-api/compare-api"

export const initCompareAll = () => {
  const dropdowns = document.querySelectorAll(".i-header-dropdown.--compare")

  dropdowns.forEach((dropdown) => {
    const itemsSelector = ".i-dropdown-product.--delete-single-compare"
    const clearButton = dropdown.querySelector(".--js-clear-compare")
    const section = dropdown.querySelector(".s-dropdown-product")
    const wrapper = dropdown.querySelector(".s-dropdown-product__buttons")

    clearButton?.addEventListener("click", async (event: Event) => {
      event.preventDefault()

      const graderClearButton = document.querySelector(".s-grader__clear-button")

      if (graderClearButton) {
        graderClearButton.dispatchEvent(new Event("click"))
        return
      }

      wrapper?.classList.add("--loading")

      const response = await compareApi.deleteAll()

      if (!response.ok) {
        wrapper?.classList.remove("--loading")

        return
      }

      const compareButtons = document.querySelectorAll(`.--js-compare-add.--active`)
      compareButtons.forEach((button) => {
        button.classList.remove("--active")
      })

      dropdown.querySelectorAll(itemsSelector).forEach((item) => {
        item.remove()
      })

      section?.classList.add("--empty")
      dropdown.classList.add("--open")
      dropdown.classList.remove("--fill")
      wrapper?.classList.remove("--loading")
    })
  })
}

initCompareAll()

export class TotalPriceItem {

  totalPriceItem: HTMLElement | null
  baseValue: HTMLElement | null
  vatExcludedValue: HTMLElement | null

  constructor(totalPriceItem: HTMLElement | null) {
    this.totalPriceItem = totalPriceItem
    this.baseValue = totalPriceItem?.querySelector(".i-total-price__value-base") ?? null
    this.vatExcludedValue = totalPriceItem?.querySelector(".i-total-price__value-vat-excluded") ?? null
  }

  setBaseValue(value: string) {
    if (!this.baseValue) return
    this.baseValue.innerHTML = value
  }

  setVatExcludedValue(value: string) {
    if (!this.vatExcludedValue) return
    this.vatExcludedValue.innerHTML = value
  }
}

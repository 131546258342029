export class errorField {
  errorItem: HTMLElement | null
  title: HTMLElement | null

  constructor(errorItem: HTMLElement | null) {
    this.errorItem = errorItem
    this.title = errorItem?.querySelector(".f-error__title") ?? null
  }

  toggle(show: boolean) {
    this.errorItem?.classList.toggle("--show", show)
  }

  setTitle(title: string) {
    if (this.title) this.title.innerHTML = title
  }
}

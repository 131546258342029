import {compareApi} from "./../compare-api/compare-api"

export const initCompareSingle = () => {
  const dropdowns = document.querySelectorAll(".i-header-dropdown.--compare")

  dropdowns.forEach((dropdown) => {
    const itemsSelector = ".i-dropdown-product.--delete-single-compare"
    const items = dropdown.querySelectorAll<HTMLElement>(itemsSelector)
    const section = dropdown.querySelector(".s-dropdown-product")

    const getItemsCount = () => dropdown.querySelectorAll(itemsSelector).length

    items.forEach((item) => {
      const deleteButton = item.querySelector<HTMLElement>(".i-dropdown-product__action")

      if (!deleteButton) return
      const productId = deleteButton.dataset.productId ?? null

      deleteButton.addEventListener("click", async () => {
        if (!productId) return

        const graderDeleteButton = document.querySelector(`.--js-remove-grader-item[data-product-id="${productId}"]`)

        if (graderDeleteButton) {
          graderDeleteButton.dispatchEvent(new Event("click"))
          return
        }

        const compareButtons = document.querySelectorAll(`.--js-compare-add[data-product-id="${productId}"]`)
        item.classList.add("--loading")

        const response = await compareApi.delete(productId)
        if (!response.ok) {
          item.classList.remove("--loading")

          return
        }

        item.remove()

        item.classList.remove("--loading")

        compareButtons.forEach((button) => {
          button.classList.remove("--active")
        })

        if (getItemsCount() === 0) {
          section?.classList.add("--empty")
          dropdown.classList.remove("--fill")
        }
      })
    })
  })
}

initCompareSingle()

import {errorField} from "../fields/error-field/error-field"

export {}

type InputTypes = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement

const events = ["blur", "input", "change"]
const inputsSelector = "input, select, textarea"
const inputsWrappersSelector = ".f-input, .f-single-select, .f-checkbox, .f-file, .f-textarea, .f-switch"

const validateInput = (input: InputTypes) => {
  const isValid = input.checkValidity()
  const message = getValidationMessage(input)
  const inputWrapper = input.closest(inputsWrappersSelector)

  console.log(inputWrapper)

  inputWrapper?.classList.toggle("--invalid", !isValid)
  const error = new errorField(inputWrapper?.querySelector(".f-error") ?? null)
  error.toggle(!isValid)
  error.setTitle(message)
  isValid ? input.setAttribute("aria-invalid", "false") : input.setAttribute("aria-invalid", "true")
}

const handleInputEvents = (input: InputTypes) => {
  events.forEach((event) => {
    input.addEventListener(event, () => {
      validateInput(input)
    })
  })
}

const getValidationMessage = (input: InputTypes) => {
  const {validity, dataset} = input
  let message = input.validationMessage

  // This could be expanded for any validity type and change message.
  // Since I doubt this will be ever used, I will just leave it only for patternMismatch.
  // But feel free to expand this.
  if (validity.patternMismatch && dataset.validityPatternMismatch) {
    message = dataset.validityPatternMismatch
  }

  if (validity.valueMissing && dataset.validityValueMissing) {
    message = dataset.validityValueMissing
  }

  return message
}

const handleFormSubmit = (form: HTMLFormElement) => {
  form.addEventListener("submit", (event: Event) => {
    const inputs = form.querySelectorAll<InputTypes>(inputsSelector)
    const isValid = form.checkValidity()
    inputs.forEach((input) => validateInput(input))
    if (!isValid) event.preventDefault()
  })
}

const initForms = () => {
  const formsSelector = "form.--validate:not(.--initialized)"
  const forms = document.querySelectorAll<HTMLFormElement>(formsSelector)

  forms.forEach((form) => {
    /**
     * Setting noValidate turns off the native client-side validation,
     * freeing us up to do whatever we want. Turning off validation with JavaScript ensures
     * the default validation still runs if JavaScript never executes for whatever reason.
     * It also prevents showing our invalid style preemptively.
     */
    form.noValidate = true

    const inputs = form.querySelectorAll<InputTypes>(inputsSelector)
    inputs.forEach(handleInputEvents)

    handleFormSubmit(form)

    form.classList.add("--initialized")

    const buttonBack = form.querySelector("button[value='back']")

    if (buttonBack) {
      buttonBack.addEventListener("click", (event: Event) => {
        event.preventDefault()
        history.back()
      })
    }
  })
}

initForms()
document.addEventListener("baseformReinit", initForms)

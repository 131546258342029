export class ProgressItem {
  progressItem: HTMLElement | null
  remaining: HTMLElement | null
  input: HTMLProgressElement | null

  constructor(progressItem: HTMLElement | null) {
    this.progressItem = progressItem
    this.remaining = progressItem?.querySelector(".i-progress__remaining") ?? null
    this.input = progressItem?.querySelector(".i-progress__input") ?? null
  }

  setRemaining(remaining: string) {
    if (!this.remaining) return
    this.remaining.innerHTML = remaining
  }

  setPercentage(percentage: number) {
    if (!this.input) return
    this.input.value = percentage
  }

  setProgress(remaining: string, percentage: number) {
    this.setRemaining(remaining)
    this.setPercentage(percentage)
    this.handleProgress()
  }

  getProgress() {
    if (!this.input) return null
    return this.input.value
  }

  handleProgress() {
    this.getProgress() == 100 ? this.progressItem?.classList.add("--gratis") : this.progressItem?.classList.remove("--gratis")
  }
}

class CartApi {
  responseSettings = {
    method: "POST",
  }

  async add(productId: string, quantity: number) {
    const response = await fetch(`/cart/add-product-ajax/${productId}/${quantity}`, this.responseSettings)
    console.log(response)
    return response
  }

  async change(productId: string, quantity: number) {
    const response = await fetch(`/cart/change-product-quantity-ajax/${productId}/${quantity}`, this.responseSettings)
    console.log(response)
    return response
  }

  async delete(productId: string) {
    const response = await fetch(`/cart/delete-product-ajax/${productId}`, this.responseSettings)
    console.log(response)
    return response
  }

  async deleteAll() {
    const response = await fetch(`/cart/delete-all-ajax`, this.responseSettings)
    console.log(response)
    return response
  }
}

export const cartApi = new CartApi()

export interface cartData {
  headerDropdown: string // rerendered header-dropdown-item--cart.twig
  product: {
    base: string
    before: string
  }
  progress: {
    percentage: string
    remaining: string
  }
  totalPrice: {
    base: string
    vatExcludedValue: string
  }
  success: boolean
  gtm?: object
  gifts?: {
    disabled: boolean
    remaining?: string
  }
}

import {cartApi, cartData} from "./../cart-api/cart-api"

export const initCartSingle = () => {
  const dropdowns = document.querySelectorAll(".i-header-dropdown.--cart")

  dropdowns.forEach((dropdown) => {
    const itemsSelector = ".i-dropdown-product.--delete-single-cart"
    const items = dropdown.querySelectorAll(itemsSelector)
    const section = dropdown.querySelector(".s-dropdown-product")

    const getItemsCount = () => dropdown.querySelectorAll(itemsSelector).length

    items.forEach((item) => {
      const deleteButton = item.querySelector<HTMLElement>(".i-dropdown-product__action")

      if (!deleteButton) {
        item.classList.remove("--loading")
        return
      }

      const productId = deleteButton.dataset.productId ?? null

      deleteButton.addEventListener("click", async () => {
        if (!productId) return

        const cartItemButton = document.querySelector<HTMLElement>(`.--js-remove-cart-item[data-product-id="${productId}"]`)

        if (cartItemButton) {
          cartItemButton.dispatchEvent(new Event("click"))
          return
        }

        item.classList.add("--loading")

        const response = await cartApi.delete(productId)

        if (!response.ok) {
          item.classList.remove("--loading")
          return
        }

        const data = (await response.json()) as cartData

        if (!data.success) {
          item.classList.remove("--loading")
          return
        }

        if (data.gtm) window.dataLayer?.push(data.gtm)
        console.log(window.dataLayer)

        item.remove()

        item.classList.remove("--loading")

        resetButtonsById(productId)

        if (getItemsCount() === 0) {
          section?.classList.add("--empty")
          dropdown.classList.remove("--fill")
        }
      })
    })
  })
}
initCartSingle()

const resetButtonsById = (id: string) => {
  const buttons = document.querySelectorAll<HTMLElement>(`.--js-cart-add.--added[data-product-id="${id}"]`)

  buttons.forEach((button) => {
    const buttonTitle = button.querySelector(".btn__title") ?? null
    const textDefault = button.dataset.textDefault ?? null

    if (buttonTitle && textDefault) {
      buttonTitle.innerHTML = textDefault
      button.classList.remove("--added")
    }
  })
}
